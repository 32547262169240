<template>
  <main class="px-2 md:px-4 py-6 flex flex-col gap-6">
    <h4
      class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
    >
      Book Keeping / Receipt
    </h4>

    <!-- //// Search and Filter ///// -->

    <div class="flex flex-col md:flex-row items-center justify-between gap-4">
      <div class="flex-grow w-full">
        <easiSearch
          placeholder="Search for employee "
          v-model="searchInput"
          @search="onSearch"
          :suggestion="false"
        />
      </div>

      <div class="flex items-center gap-4">
        <easiButton
          v-if="storeBookKeepingReceipts.length"
          rounded="2xl"
          variant="outlined"
          color="gray"
          class="bg-white text-dark-600 gap-2"
          :loading="loading"
          @click="downloadReceipts()"
        >
          <img src="@/assets/icons/newDownload.svg" />

          Download
        </easiButton>

        <easiButton @click="$router.push({ name: 'AddReceipt' })" class="gap-2">
          <i class="pi pi-plus text-sm" /> New Receipt</easiButton
        >
      </div>
    </div>

    <!-- //// Table ///// -->
    <ListReceipt v-model:loading="isLoading" />
    <easiLoading v-if="loadingModal" />
  </main>
</template>

<script setup>
import ListReceipt from "@/components/BookKeeping/Receipt/ListReceipt";
import { computed, ref, onMounted, watch } from "vue";
import {
  GETRECEIPTS,
  DOWNLOAD_ALL_RECEIPTS,
} from "@/services/book-keeping/receipts";
import { useDataStore } from "@/stores/data";
import { helperFunctions } from "@/composable/helperFunctions";
import { debouncedValue } from "@/composable/debouncedValue";
import { useToast } from "vue-toastification";
const { downloadBase64 } = helperFunctions;

const toast = useToast();

const store = useDataStore();
const storeBookKeepingReceipts = computed(
  () =>
    (store.getBookkeepingReceipts &&
      store.getBookkeepingReceipts.docs &&
      store.getBookkeepingReceipts.docs) ||
    []
);
const searchInput = ref("");
const loading = ref();
const loadingModal = ref(false);
const debounceSearch = debouncedValue(searchInput);
const downloadReceipts = async () => {
  try {
    loading.value = true;
    const res = await DOWNLOAD_ALL_RECEIPTS();
    if (res.success === true) {
      const base64 = res.data.base64s[0].Base64Content;
      const fileName = res.data.base64s[0].Filename;
      downloadBase64(base64, fileName, null, true);
    } else {
      toast.error("Download failed.");
    }
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const onSearch = async () => {
  try {
    searchInput.value ? (isLoading.value = true) : (loadingModal.value = true);
    await GETRECEIPTS({ search: searchInput.value });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
    loadingModal.value = false;
  }
};
const isLoading = ref(false);

watch(debounceSearch, () => {
  onSearch();
});
onMounted(async () => {
  try {
    loadingModal.value = !storeBookKeepingReceipts.value.length;
    await GETRECEIPTS();
  } catch (error) {
    console.log(error);
  } finally {
    loadingModal.value = false;
  }
});
</script>

<style></style>

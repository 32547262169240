<template>
  <div class="flex min-h-screen flex-col gap-2 sm:gap-4">
    <div class="flex items-center justify-between gap-4">
      <h3 class="font-semibold text-lg md:text-xl">All Items</h3>
      <easiPaginAsync
        v-if="visibleData.length"
        infoOnly
        leftAlign
        :totalRecords="totalRecords"
        v-model:rowsPerPage="paginationPayload.limit"
        v-model:page="paginationPayload.page"
      />
    </div>

    <easiPlainTable
        v-if="visibleData.length"
      :data="visibleData"
      :headers="tableHeaders"
      :loading="loading"
      v-model:selected="selectedData"
    >
      <template #name="slotsProp">
        <div class="flex items-center justify-start">
          {{
            slotsProp.data &&
            slotsProp.data.client &&
            slotsProp.data.client.firstName
          }}
          {{
            slotsProp.data &&
            slotsProp.data.client &&
            slotsProp.data.client.lastName
          }}
        </div>
      </template>
      <template #email="slotsProp">
        <div class="flex items-center justify-start">
          {{
            slotsProp.data &&
            slotsProp.data.client &&
            slotsProp.data.client.email
          }}
        </div>
      </template>
      <template #action="slotsProp">
        <div class="flex items-center justify-center">
          <easiPopover :data="slotsProp.data" :options="dropdownOptions">
            <i class="pi pi-ellipsis-v text-dark-600" />
          </easiPopover>
        </div>
      </template>
      <template #status="slotProps">
        <div
          :class="[
            getStatusColor(slotProps.data.status),
            'px-4 py-1.5 rounded-full capitalize text-center',
          ]"
        >
          {{ slotProps.data.status && slotProps.data.status.toLowerCase() }}
        </div>
      </template>
    </easiPlainTable>
    <div v-else class="text-center">No Receipt to show</div>
    <easiPaginAsync
      v-if="visibleData.length"
      :totalRecords="totalRecords"
      v-model:rowsPerPage="paginationPayload.limit"
      v-model:page="paginationPayload.page"
    />
  </div>

  <!-- //////  Delete Modal ////// -->
  <easiAlert
    :icon="opsIcon"
    v-if="deleteModal"
    :title="`<span class='text-xl'>Are you sure you want to delete <br/> this item?</span>`"
  >
    <div class="pb-4 px-6 grid grid-cols-2 gap-4">
      <easiButton
        variant="outlined"
        size="medium"
        @click="deleteModal = false"
        block
        >Cancel</easiButton
      >
      <easiButton
        size="medium"
        :loading="deleteLoading"
        @click="handleDelete"
        block
        >Yes</easiButton
      >
    </div>
  </easiAlert>

  <!-- //////  Alert Modal ////// -->

  <easiAlert v-if="isAlert" :caption="alertMessage">
    <div class="pb-4 px-6">
      <easiButton
        size="medium"
        :loading="loading"
        @click="isAlert = false"
        block
        >Okay!</easiButton
      >
    </div>
  </easiAlert>
</template>

<script setup>
import opsIcon from "@/assets/icons/empty-state.svg";
import { ref, computed, watch, reactive } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { useRouter } from "vue-router";
import { GETRECEIPTS } from "@/services/book-keeping/receipts";

const { formatAmount, formatDate } = helperFunctions;
const store = useDataStore();
const router = useRouter();

const alertMessage = ref("");
const isAlert = ref(false);

const props = defineProps({
  loading: Boolean,
});
const emit = defineEmits(["update:loading"]);

const deleteLoading = ref(false);
const deleteModal = ref(false);
const selectedItem = ref(null);
const paginationPayload = reactive({
  limit: 20,
  page: 1,
});
watch(paginationPayload, () => {
  queryInvoice();
});
const queryInvoice = async () => {
  try {
    emit("update:loading", true);
    await GETRECEIPTS(paginationPayload);
  } catch (error) {
    console.log(error);
  } finally {
    emit("update:loading", false);
  }
};
const selectedData = ref([]);
const totalRecords = computed(() =>
  store.getBookkeepingReceipts && store.getBookkeepingReceipts.paginationData
    ? store.getBookkeepingReceipts.paginationData.totalRecords
    : 0
);
const storeBookKeepingReceipts = computed(
  () => store.getBookkeepingReceipts && store.getBookkeepingReceipts.docs && store.getBookkeepingReceipts.docs || []
);
const visibleData = computed(() => {
  return storeBookKeepingReceipts.value || [];
});
// const visibleData = ref([
//   {
//     name: "Seyi law Balogun",
//     amount: 1000,
//     receiptNumber: "REC2379",
//     status: "Paid",
//     createdAt: new Date(),
//     _id: 1,
//   },
// ]);

const tableHeaders = [
  {
    style: ``,
    title: "NAME OF CUSTOMER",
    field: "name",
    type: "name",
  },
  {
    style: ``,
    title: "AMOUNT",
    field: "grandTotalAmount",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },
  {
    style: ``,
    title: "Receipt No",
    field: "receiptNo",
  },
  {
    style: ``,
    title: "Date",
    field: "createdAt",
    type: "date",
    format: (val) => {
      return val ? formatDate(val, "DD/MM/YYYY") : "--";
    },
  },
  {
    style: ``,
    title: "STATUS",
    field: "status",
    type: "status",
  },
  {
    title: "ACTION",
    field: "",
    type: "action",
  },
];
function getStatusColor(status) {
  const obj = {
    Pending: `text-error bg-error-background`,
    Paid: `text-success bg-statusGreen`,
  };

  return obj[status] || "";
}
const selectedInvoice = ref({});

const dropdownOptions = [
  {
    title: "View",
    action: (val) => {
      router.push({
        name: "ViewReceipt",
        params: { id: val._id},
        query: {
          companyId: val.companyId,
        },
      });
    },
  },
  // {
  //   title: "Send",
  //   action: (val) => {
  //     console.log("val is", val);
  //     selectedInvoice.value = val;

  //     sendModal.value = true;
  //   },
  // },
  // {
  //   title: "Download",
  //   action: (val) => {
  //     selectedInvoice.value = val;
  //     sendInvoice(true);
  //   },
  // },
];

const handleDelete = () => {
  deleteLoading.value = true;

  setTimeout(() => {
    deleteLoading.value = false;
    deleteModal.value = false;

    alertMessage.value = "Item successfully deleted";
    isAlert.value = true;
  }, 3000);
};
</script>

<style></style>
